import React from 'react';

const UxUiAccessibility = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.1662 25.2595C23.3231 25.2633 22.4877 25.0936 21.7077 24.7602C20.9278 24.4268 20.2188 23.9362 19.6214 23.3166C19.024 22.6971 18.5499 21.9609 18.2264 21.15C17.903 20.3391 17.7366 19.4696 17.7366 18.5916V13.2805C17.8437 11.5842 18.5662 9.99368 19.7576 8.83172C20.949 7.66975 22.5201 7.02344 24.1523 7.02344C25.7845 7.02344 27.3556 7.66975 28.547 8.83172C29.7384 9.99368 30.461 11.5842 30.5681 13.2805V18.5916C30.5681 20.36 29.8936 22.056 28.693 23.3064C27.4924 24.5569 25.8641 25.2595 24.1662 25.2595ZM24.1662 7.94061C22.8358 7.94061 21.5598 8.4911 20.6191 9.47089C19.6784 10.4507 19.15 11.7793 19.15 13.165V18.4763C19.0987 19.1931 19.1899 19.9131 19.4181 20.5915C19.6463 21.2699 20.0065 21.892 20.476 22.4188C20.9455 22.9456 21.5143 23.3658 22.1469 23.6531C22.7794 23.9405 23.4622 24.089 24.1523 24.089C24.8424 24.089 25.525 23.9405 26.1576 23.6531C26.7902 23.3658 27.3591 22.9456 27.8286 22.4188C28.2982 21.892 28.6582 21.2699 28.8864 20.5915C29.1145 19.9131 29.2059 19.1931 29.1546 18.4763V13.165C29.1258 11.8044 28.5883 10.5096 27.6566 9.55545C26.725 8.60129 25.4728 8.06337 24.1662 8.05619V7.94061Z"
      fill="#475467"
    />
    <path
      d="M31.1785 42.0004H16.8228C15.6101 42.0004 14.4469 41.4986 13.5893 40.6054C12.7318 39.7122 12.25 38.5007 12.25 37.2376V31.7243C12.314 31.074 12.5196 30.4476 12.851 29.8924C13.1823 29.3373 13.6307 28.8679 14.1623 28.5204C15.2523 27.6521 16.4951 27.0146 17.8205 26.6441L22.2548 25.3453C22.4312 25.2917 22.6208 25.3132 22.7819 25.4052C22.943 25.4972 23.0625 25.6521 23.1139 25.8358C23.1654 26.0195 23.1446 26.2173 23.0562 26.3851C22.9679 26.5529 22.8191 26.6772 22.6427 26.7308L18.1808 28.0295C17.0396 28.3713 15.9686 28.9293 15.0214 29.675C14.384 30.1946 13.6357 30.9738 13.6357 31.7243V37.2087C13.6357 38.0891 13.9715 38.9332 14.5692 39.5557C15.1669 40.1783 15.9776 40.5282 16.8228 40.5282H31.1785C31.5981 40.5321 32.0142 40.4488 32.4025 40.2833C32.7909 40.1179 33.1437 39.8736 33.4404 39.5645C33.7371 39.2555 33.9717 38.888 34.1306 38.4835C34.2894 38.079 34.3694 37.6457 34.3657 37.2087V31.7243C34.3657 30.9738 33.6174 30.1946 32.98 29.675C32.0362 28.9235 30.9641 28.365 29.8206 28.0295L25.4973 26.7886C25.3234 26.7288 25.1766 26.6046 25.0847 26.4397C24.9928 26.2748 24.9621 26.0805 24.9984 25.8936C25.0501 25.7024 25.1725 25.5403 25.3388 25.4429C25.505 25.3455 25.7015 25.3209 25.8853 25.3742L30.2086 26.6441C31.5255 27.0146 32.7593 27.6522 33.8391 28.5204C34.3707 28.8679 34.8192 29.3373 35.1506 29.8924C35.4819 30.4476 35.6874 31.074 35.7514 31.7243V37.2087C35.755 37.8366 35.6394 38.4591 35.4112 39.0402C35.1831 39.6214 34.8468 40.1499 34.4219 40.5952C33.9969 41.0405 33.4914 41.3938 32.9348 41.635C32.3782 41.8761 31.7814 42.0004 31.1785 42.0004Z"
      fill="#475467"
    />
    <path
      d="M22.4487 26.7896C22.2649 26.7896 22.0886 26.7135 21.9587 26.5782C21.8287 26.4428 21.7559 26.2593 21.7559 26.0679V24.538C21.7559 24.3466 21.8287 24.1631 21.9587 24.0278C22.0886 23.8925 22.2649 23.8164 22.4487 23.8164C22.6325 23.8164 22.8086 23.8925 22.9386 24.0278C23.0685 24.1631 23.1415 24.3466 23.1415 24.538V26.0679C23.1415 26.2593 23.0685 26.4428 22.9386 26.5782C22.8086 26.7135 22.6325 26.7896 22.4487 26.7896Z"
      fill="#475467"
    />
    <path
      d="M25.6914 26.8474C25.5051 26.8399 25.3289 26.7572 25.1997 26.6173C25.0705 26.4773 24.9984 26.291 24.9985 26.0968V24.538C24.9985 24.3466 25.0716 24.1631 25.2015 24.0278C25.3315 23.8925 25.5076 23.8164 25.6914 23.8164C25.8751 23.8164 26.0513 23.8925 26.1812 24.0278C26.3112 24.1631 26.3842 24.3466 26.3842 24.538V26.0968C26.3844 26.291 26.3123 26.4773 26.1831 26.6173C26.0539 26.7572 25.8776 26.8399 25.6914 26.8474Z"
      fill="#475467"
    />
    <path
      d="M24.0008 32.9955C22.7718 32.8021 21.6629 32.1193 20.9081 31.0907C20.1532 30.0622 19.8113 28.7686 19.9546 27.4826C19.9283 27.1464 19.9283 26.8086 19.9546 26.4724C19.9646 26.3783 19.9929 26.2872 20.038 26.205C20.083 26.1228 20.1439 26.0511 20.2166 25.9943C20.2893 25.9375 20.3724 25.8969 20.4608 25.8748C20.5492 25.8528 20.6411 25.8497 20.7306 25.866C20.9099 25.8946 21.0713 25.9954 21.1801 26.1465C21.2889 26.2976 21.3364 26.4871 21.3125 26.6743C21.2988 26.9435 21.2988 27.2134 21.3125 27.4826C21.3125 29.6763 22.5319 31.5523 24.0839 31.5523C25.6359 31.5523 26.8553 29.6763 26.8553 27.4826C26.869 27.2134 26.869 26.9435 26.8553 26.6743C26.8314 26.4871 26.8791 26.2976 26.9879 26.1465C27.0967 25.9954 27.2581 25.8946 27.4373 25.866C27.5284 25.8496 27.6218 25.8524 27.7119 25.8741C27.8019 25.8959 27.887 25.936 27.9619 25.9925C28.0368 26.049 28.1 26.1209 28.1479 26.2032C28.1959 26.2856 28.2275 26.3771 28.241 26.4724C28.241 26.8188 28.241 27.1651 28.241 27.4826C28.3103 28.1346 28.2555 28.7945 28.0799 29.4244C27.9043 30.0544 27.6114 30.6422 27.2176 31.1541C26.8238 31.6661 26.337 32.0922 25.7851 32.4082C25.2331 32.7242 24.6269 32.9237 24.0008 32.9955Z"
      fill="#475467"
    />
    <path
      d="M26.245 14.0872C25.6509 14.0837 25.0646 13.9458 24.5267 13.6831C23.9832 13.4055 23.5016 13.0121 23.1133 12.5284C22.8727 12.2046 22.5444 11.9634 22.171 11.8357C21.3673 11.6336 20.2311 12.2976 18.9562 13.7408C18.8916 13.8107 18.8141 13.866 18.7284 13.904C18.6427 13.9419 18.5505 13.9618 18.4574 13.9618C18.3643 13.9618 18.272 13.9419 18.1863 13.904C18.1005 13.866 18.0232 13.8107 17.9586 13.7408C17.8316 13.6059 17.7605 13.4245 17.7605 13.2356C17.7605 13.0467 17.8316 12.8655 17.9586 12.7306C19.6491 10.8256 21.1455 10.046 22.5035 10.3924C23.1481 10.5642 23.7219 10.9491 24.1387 11.4893C24.4117 11.8376 24.7515 12.1228 25.1363 12.3265C25.8846 12.7017 27.0208 12.9037 29.4319 11.4893C29.5882 11.4157 29.7652 11.4052 29.9286 11.4597C30.0919 11.5142 30.2301 11.6298 30.3163 11.7842C30.4025 11.9387 30.4306 12.121 30.3953 12.2958C30.3599 12.4706 30.2635 12.6256 30.1248 12.7306C28.9713 13.5363 27.6315 14.0048 26.245 14.0872Z"
      fill="#475467"
    />
    <path
      d="M37.5798 23.3823C36.0484 23.3747 34.5823 22.7359 33.502 21.6054C32.4218 20.4749 31.8154 18.9447 31.8154 17.3497V12.6158C31.9168 11.0917 32.5696 9.66454 33.6421 8.62224C34.7146 7.57993 36.1268 7 37.5936 7C39.0605 7 40.4727 7.57993 41.5452 8.62224C42.6177 9.66454 43.2706 11.0917 43.372 12.6158V17.2919C43.3757 18.0879 43.2288 18.877 42.9397 19.6139C42.6506 20.3508 42.2248 21.0212 41.6869 21.5867C41.1491 22.1523 40.5097 22.6017 39.805 22.9098C39.1002 23.2179 38.3441 23.3785 37.5798 23.3823ZM37.5798 8.0553C36.4184 8.0553 35.3048 8.53579 34.4836 9.39107C33.6624 10.2464 33.2011 11.4063 33.2011 12.6158V17.2919C33.1529 17.9233 33.2306 18.558 33.4295 19.1566C33.6284 19.7551 33.9442 20.3044 34.3568 20.7696C34.7694 21.2349 35.2699 21.6062 35.827 21.8602C36.3841 22.1141 36.9855 22.2449 37.5936 22.2449C38.2017 22.2449 38.8033 22.1141 39.3604 21.8602C39.9175 21.6062 40.4181 21.2349 40.8307 20.7696C41.2433 20.3044 41.5589 19.7551 41.7578 19.1566C41.9567 18.558 42.0345 17.9233 41.9863 17.2919V12.558C41.9646 11.3559 41.4906 10.2106 40.6666 9.36852C39.8426 8.52646 38.7342 8.05509 37.5798 8.0553Z"
      fill="#475467"
    />
    <path
      d="M28.7391 27.7432C28.6442 27.7501 28.5492 27.7327 28.4622 27.6924C28.3752 27.6522 28.299 27.5904 28.2403 27.5124C28.1732 27.4451 28.1199 27.3642 28.0835 27.2749C28.047 27.1856 28.0283 27.0896 28.0283 26.9926C28.0283 26.8956 28.047 26.7997 28.0835 26.7104C28.1199 26.6211 28.1732 26.5406 28.2403 26.4733L28.6006 26.1555C29.5701 25.3719 30.6828 24.8024 31.8708 24.4814L35.8338 23.2979C36.0137 23.2531 36.2032 23.2822 36.3631 23.3789C36.523 23.4756 36.6411 23.6327 36.6929 23.8176C36.7444 24.0013 36.7238 24.1987 36.6354 24.3666C36.5471 24.5344 36.3982 24.6586 36.2218 24.7122L32.2588 25.8669C31.2539 26.1562 30.3126 26.6464 29.4874 27.3101L29.1826 27.5702C29.0567 27.6764 28.901 27.737 28.7391 27.7432Z"
      fill="#475467"
    />
    <path
      d="M43.8426 38.2764H35.0573C34.8736 38.2764 34.6972 38.2003 34.5673 38.065C34.4374 37.9297 34.3645 37.7462 34.3645 37.5548C34.3645 37.3634 34.4374 37.1799 34.5673 37.0446C34.6972 36.9092 34.8736 36.8332 35.0573 36.8332H43.8426C44.5776 36.8332 45.2824 36.5288 45.8022 35.9875C46.3219 35.4462 46.6139 34.7122 46.6139 33.9467V29.0683C46.6139 28.5776 46.1705 27.9427 45.3945 27.3076C44.5679 26.6463 43.6271 26.1564 42.6231 25.8644L38.7709 24.7386C38.6751 24.7225 38.5837 24.6858 38.5025 24.6304C38.4212 24.5751 38.3521 24.5026 38.2995 24.4176C38.2469 24.3327 38.2119 24.237 38.197 24.1371C38.1821 24.0373 38.1875 23.9352 38.2129 23.8376C38.2382 23.7401 38.283 23.6493 38.3443 23.5709C38.4056 23.4925 38.482 23.4284 38.5686 23.3828C38.6552 23.3371 38.7499 23.3107 38.8468 23.3056C38.9438 23.3005 39.0406 23.3167 39.1312 23.3532L42.9834 24.4789C44.1777 24.8058 45.2984 25.3746 46.2814 26.153C46.7557 26.4709 47.1559 26.895 47.4531 27.3943C47.7503 27.8936 47.9371 28.4556 47.9996 29.0394V33.9178C48.0033 34.4888 47.8985 35.0547 47.6913 35.5834C47.484 36.112 47.1783 36.5929 46.7919 36.9981C46.4055 37.4032 45.946 37.7247 45.4399 37.9441C44.9337 38.1635 44.3908 38.2764 43.8426 38.2764Z"
      fill="#475467"
    />
    <path
      d="M36.0827 24.7412C35.899 24.7412 35.7228 24.6655 35.5929 24.5301C35.4629 24.3948 35.3899 24.211 35.3899 24.0196V22.663C35.3899 22.4716 35.4629 22.2881 35.5929 22.1528C35.7228 22.0175 35.899 21.9414 36.0827 21.9414C36.2665 21.9414 36.4427 22.0175 36.5726 22.1528C36.7025 22.2881 36.7756 22.4716 36.7756 22.663V24.0196C36.7756 24.211 36.7025 24.3948 36.5726 24.5301C36.4427 24.6655 36.2665 24.7412 36.0827 24.7412Z"
      fill="#475467"
    />
    <path
      d="M38.9647 24.7701C38.7783 24.7702 38.5992 24.6954 38.4649 24.5608C38.3305 24.4262 38.2513 24.2425 38.2441 24.0485V22.663C38.2513 22.469 38.3305 22.2856 38.4649 22.1511C38.5992 22.0165 38.7783 21.9413 38.9647 21.9414C39.1485 21.9414 39.3248 22.0175 39.4548 22.1528C39.5847 22.2881 39.6576 22.4716 39.6576 22.663V24.0485C39.6576 24.2399 39.5847 24.4234 39.4548 24.5587C39.3248 24.694 39.1485 24.7701 38.9647 24.7701Z"
      fill="#475467"
    />
    <path
      d="M37.4679 30.253C36.354 30.0876 35.3458 29.4767 34.6576 28.5497C33.9693 27.6227 33.6552 26.4527 33.7819 25.2883C33.7819 24.9708 33.7819 24.6531 33.7819 24.3644C33.7946 24.2697 33.8251 24.1784 33.8717 24.0959C33.9183 24.0135 33.98 23.9414 34.0534 23.8838C34.1267 23.8262 34.2102 23.7842 34.2992 23.7601C34.3881 23.7361 34.4808 23.7307 34.5718 23.7439C34.6628 23.7572 34.7503 23.7891 34.8294 23.8377C34.9086 23.8862 34.9779 23.9505 35.0333 24.0269C35.0886 24.1033 35.129 24.1901 35.152 24.2827C35.1751 24.3753 35.1803 24.4719 35.1676 24.5667C35.1531 24.807 35.1531 25.048 35.1676 25.2883C35.1676 27.1934 36.2207 28.8097 37.4679 28.8097C38.715 28.8097 39.7404 27.1934 39.7404 25.2883C39.7694 25.0487 39.7694 24.8063 39.7404 24.5667C39.7166 24.3718 39.7672 24.1749 39.8811 24.0184C39.9951 23.8619 40.1634 23.7581 40.35 23.7295C40.5327 23.7126 40.7147 23.769 40.8588 23.887C41.003 24.005 41.0986 24.1756 41.1261 24.3644C41.1547 24.6716 41.1547 24.9811 41.1261 25.2883C41.2463 26.447 40.9332 27.6093 40.2519 28.5338C39.5707 29.4584 38.5736 30.0741 37.4679 30.253Z"
      fill="#475467"
    />
    <path
      d="M39.3537 13.4221C38.8146 13.4322 38.2815 13.3033 37.8018 13.0468C37.3019 12.8004 36.8578 12.4462 36.4991 12.0078C36.2921 11.7302 36.0133 11.5197 35.6955 11.4014C35.0027 11.2282 34.0327 11.8346 32.9241 13.1046C32.79 13.2387 32.6112 13.3136 32.4253 13.3136C32.2394 13.3136 32.0606 13.2387 31.9265 13.1046C31.8594 13.0373 31.8061 12.9564 31.7697 12.8671C31.7332 12.7779 31.7144 12.6819 31.7144 12.5849C31.7144 12.4879 31.7332 12.3919 31.7697 12.3027C31.8061 12.2134 31.8594 12.1325 31.9265 12.0652C33.4507 10.3622 34.6978 9.66947 36.0281 9.95812C36.6239 10.1216 37.1506 10.4872 37.5247 10.9972C37.7698 11.2984 38.0715 11.544 38.4114 11.7188C39.0488 12.0075 40.0466 12.2098 42.1529 10.9398C42.2329 10.8941 42.3208 10.8651 42.4115 10.8549C42.5022 10.8446 42.5941 10.8531 42.6816 10.8799C42.7692 10.9067 42.8507 10.9515 42.9217 11.0113C42.9926 11.0711 43.0515 11.1448 43.095 11.2283C43.1859 11.3932 43.2107 11.5886 43.164 11.7724C43.1173 11.9561 43.003 12.1133 42.8457 12.2097C41.7999 12.9171 40.5979 13.3345 39.3537 13.4221Z"
      fill="#475467"
    />
    <path
      d="M10.6693 23.3823C9.13792 23.3747 7.67165 22.7359 6.59139 21.6054C5.51114 20.4749 4.90477 18.9447 4.90479 17.3497V12.6158C5.00619 11.0917 5.65897 9.66454 6.73145 8.62224C7.80393 7.57993 9.2163 7 10.6832 7C12.15 7 13.5622 7.57993 14.6347 8.62224C15.7072 9.66454 16.36 11.0917 16.4614 12.6158V17.2919C16.465 18.0879 16.3181 18.877 16.029 19.6139C15.7399 20.3508 15.3143 21.0212 14.7765 21.5867C14.2386 22.1523 13.599 22.6017 12.8943 22.9098C12.1896 23.2179 11.4336 23.3785 10.6693 23.3823ZM10.6693 8.0553C9.50797 8.0553 8.39416 8.53579 7.57298 9.39107C6.7518 10.2464 6.29047 11.4063 6.29047 12.6158V17.2919C6.24229 17.9233 6.32011 18.558 6.519 19.1566C6.71789 19.7551 7.03352 20.3044 7.44612 20.7696C7.85871 21.2349 8.3593 21.6062 8.91638 21.8602C9.47346 22.1141 10.0751 22.2449 10.6832 22.2449C11.2913 22.2449 11.8927 22.1141 12.4498 21.8602C13.0069 21.6062 13.5074 21.2349 13.92 20.7696C14.3326 20.3044 14.6483 19.7551 14.8472 19.1566C15.0461 18.558 15.1239 17.9233 15.0757 17.2919V12.558C15.0539 11.3559 14.5802 10.2106 13.7561 9.36852C12.9321 8.52646 11.8237 8.05509 10.6693 8.0553Z"
      fill="#475467"
    />
    <path
      d="M12.9423 38.2762H4.15706C3.05454 38.2762 1.99715 37.8201 1.21755 37.0081C0.437951 36.1961 0 35.0948 0 33.9465V29.0682C0.0617897 28.4806 0.251143 27.9157 0.553632 27.4156C0.856121 26.9156 1.26383 26.4934 1.74598 26.1817C2.71557 25.3981 3.82825 24.8286 5.01618 24.5077L8.97923 23.3241C9.15866 23.2863 9.34527 23.3182 9.5036 23.414C9.66192 23.5097 9.78097 23.6628 9.83835 23.8438C9.8898 24.0276 9.86919 24.225 9.78084 24.3928C9.69248 24.5606 9.54367 24.6849 9.36726 24.7385L5.40422 25.8931C4.39929 26.1824 3.45801 26.6726 2.63284 27.3364C2.32823 27.5282 2.06509 27.7838 1.85999 28.0869C1.65489 28.3899 1.51229 28.7338 1.44117 29.0971V33.9754C1.44117 34.741 1.73319 35.4749 2.25293 36.0162C2.77266 36.5576 3.47753 36.8619 4.21254 36.8619H12.9978C13.1815 36.8619 13.3577 36.938 13.4876 37.0733C13.6176 37.2086 13.6906 37.3921 13.6906 37.5835C13.6906 37.7749 13.6176 37.9584 13.4876 38.0937C13.3577 38.2291 13.1815 38.3051 12.9978 38.3051L12.9423 38.2762Z"
      fill="#475467"
    />
    <path
      d="M19.2047 27.7118C19.0419 27.7101 18.885 27.649 18.7613 27.5388C18.6818 27.4464 18.5881 27.3682 18.4841 27.3076C17.6574 26.6463 16.7166 26.1564 15.7127 25.8644L11.8606 24.7386C11.7648 24.7225 11.6733 24.6858 11.592 24.6304C11.5108 24.5751 11.4417 24.5026 11.389 24.4176C11.3364 24.3327 11.3014 24.237 11.2865 24.1371C11.2716 24.0373 11.2771 23.9352 11.3024 23.8376C11.3278 23.7401 11.3726 23.6493 11.4339 23.5709C11.4951 23.4925 11.5716 23.4284 11.6582 23.3828C11.7447 23.3371 11.8396 23.3107 11.9366 23.3056C12.0335 23.3005 12.1304 23.3167 12.2209 23.3532L16.073 24.4789C17.2673 24.8058 18.388 25.3746 19.371 26.153L19.6759 26.4419C19.8029 26.5768 19.8738 26.7579 19.8738 26.9468C19.8738 27.1357 19.8029 27.3172 19.6759 27.4521C19.6178 27.5257 19.546 27.5865 19.4652 27.6311C19.3843 27.6757 19.2958 27.703 19.2047 27.7118Z"
      fill="#475467"
    />
    <path
      d="M9.17356 24.7412C8.9898 24.7412 8.81363 24.6655 8.68369 24.5301C8.55376 24.3948 8.48071 24.211 8.48071 24.0196V22.663C8.48071 22.4716 8.55376 22.2881 8.68369 22.1528C8.81363 22.0175 8.9898 21.9414 9.17356 21.9414C9.35731 21.9414 9.53365 22.0175 9.66359 22.1528C9.79352 22.2881 9.8664 22.4716 9.8664 22.663V24.0196C9.8664 24.211 9.79352 24.3948 9.66359 24.5301C9.53365 24.6655 9.35731 24.7412 9.17356 24.7412Z"
      fill="#475467"
    />
    <path
      d="M12.0553 24.7696C11.8689 24.7697 11.6897 24.6949 11.5553 24.5603C11.4209 24.4257 11.3419 24.242 11.3347 24.048V22.6625C11.3673 22.4922 11.4554 22.3389 11.5842 22.2288C11.7131 22.1186 11.8747 22.0586 12.0414 22.0586C12.2081 22.0586 12.3696 22.1186 12.4985 22.2288C12.6273 22.3389 12.7156 22.4922 12.7481 22.6625V24.048C12.7481 24.2394 12.6751 24.4229 12.5452 24.5582C12.4152 24.6935 12.2391 24.7696 12.0553 24.7696Z"
      fill="#475467"
    />
    <path
      d="M10.5585 30.2532C9.44467 30.0879 8.43664 29.477 7.74841 28.55C7.06017 27.6229 6.74606 26.453 6.87271 25.2886C6.87271 24.9711 6.87271 24.6534 6.87271 24.3647C6.9065 24.1735 7.00751 24.0026 7.15621 23.8852C7.3049 23.7677 7.49048 23.7122 7.67635 23.7298C7.85786 23.7651 8.01935 23.8717 8.12764 24.0275C8.23594 24.1833 8.28284 24.3763 8.2584 24.567C8.24395 24.8073 8.24395 25.0483 8.2584 25.2886C8.2584 27.1937 9.3114 28.81 10.5585 28.81C11.8056 28.81 12.8311 27.1937 12.8311 25.2886C12.8455 25.0483 12.8455 24.8073 12.8311 24.567C12.8073 24.3721 12.8578 24.1752 12.9718 24.0187C13.0858 23.8622 13.2543 23.7584 13.4409 23.7298C13.6235 23.7129 13.8053 23.7693 13.9495 23.8873C14.0937 24.0053 14.1893 24.1759 14.2168 24.3647C14.2451 24.6719 14.2451 24.9814 14.2168 25.2886C14.344 26.4486 14.0335 27.6149 13.351 28.5412C12.6685 29.4674 11.6673 30.0812 10.5585 30.2532Z"
      fill="#475467"
    />
    <path
      d="M12.5547 13.4221C12.007 13.4288 11.4656 13.3002 10.975 13.0468C10.4876 12.7928 10.054 12.4395 9.70007 12.0078C9.493 11.7302 9.21424 11.5197 8.89643 11.4014C8.20359 11.2282 7.2336 11.8346 6.12505 13.1046C5.99088 13.2387 5.81216 13.3136 5.62623 13.3136C5.44029 13.3136 5.26157 13.2387 5.1274 13.1046C5.06034 13.0373 5.00686 12.9564 4.97043 12.8671C4.934 12.7779 4.91528 12.6819 4.91528 12.5849C4.91528 12.4879 4.934 12.3919 4.97043 12.3027C5.00686 12.2134 5.06034 12.1325 5.1274 12.0652C6.65166 10.3622 7.89872 9.66947 9.22898 9.95812C9.82487 10.1216 10.3514 10.4872 10.7255 10.9972C10.9706 11.2984 11.2724 11.544 11.6123 11.7188C12.222 12.0075 13.2475 12.2098 15.3538 10.9398C15.4338 10.8941 15.5217 10.8651 15.6124 10.8549C15.7031 10.8446 15.7948 10.8531 15.8824 10.8799C15.9699 10.9067 16.0516 10.9515 16.1226 11.0113C16.1935 11.0711 16.2524 11.1448 16.296 11.2284C16.3869 11.3932 16.4117 11.5886 16.365 11.7724C16.3183 11.9561 16.2039 12.1133 16.0466 12.2097C15.0009 12.9171 13.7988 13.3345 12.5547 13.4221Z"
      fill="#475467"
    />
  </svg>
);

export default UxUiAccessibility;
